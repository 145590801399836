import React , {useState} from "react";
import "../styles/newdelliverychallan.css";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { Button} from "@mui/material";
import { getDatabase, ref, set, push } from "firebase/database"; // Import Realtime Database methods
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { savePaymentType } from "../firebase";
const Newdelliverychallan = () => {
  const navigate = useNavigate();
    const db = getDatabase(); // Initialize Realtime Database instance
  const [paymentType, setPaymentType] = useState(""); // For storing the selected payment type
  const [details] = useState({}); // Replace with your actual details object
    const [invoice, setInvoice] = useState({
      customerName: "",
      invoiceDate: "",
      dueDate: "",
      poDate:"",
      deliveryDate:"",
      SalesOrderNo:"",
      transportDetails:"",
      placeofSupply:"",
      paymentMethod:"",
      pob:"",
      items: [],
      subTotal: 0,
      sgst: 0,
      cgst: 0,
      totalAmount: 0,
    });
  
  const handleSave = async () => {
      try {
        await savePaymentType(paymentType, details);
        alert("Payment type saved successfully!");
      } catch (error) {
        console.error("Error saving payment type:", error);
      }
      navigate('/deliverychallan');
    };
  
    const handleGoBack = () => {
      navigate(-1); // Navigates to the previous page in the history stack
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInvoice((prev) => ({
        ...prev,
        [name]: value,
      }));
     
    };
  
    const addInvoiceToDatabase = async () => {
      try {
        const invoiceRef = ref(db, "invoices"); // Reference to 'invoices' collection
        const newInvoiceRef = push(invoiceRef); // Generate a new unique key
        await set(newInvoiceRef, invoice); // Save the invoice data
        alert("Invoice saved successfully!");
      } catch (error) {
        console.error("Error saving invoice:", error);
      }
      navigate('/deliverychallan');
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      addInvoiceToDatabase();
    };
  
    
    const handlePrint = () => {
      navigate('/newinvoice'); // Navigates to the previous page in the history stack
    };
    const handleEinvoice = () => {
      navigate('/einvoice'); // Navigates to the previous page in the history stack
    };
    const handleEwaybill = () => {
      navigate('/ewaybill'); // Navigates to the previous page in the history stack
    };
    const handleNewInvoice = () => {
      navigate('/ewaybill'); // Navigates to the previous page in the history stack
    };
  

    const handleCancel = () => {
        if (window.confirm("Are you sure you want to cancel? All unsaved changes will be lost.")) {
          // Reset the form fields
          const form = document.getElementById("salesOrderForm");
          if (form) {
            form.reset();
          }
          // Refresh the page
          window.location.reload();
        }
      };
  return (
    <body>
    <div className="newdelliverychallan-page">
   
    <ArrowCircleLeftOutlinedIcon
    onClick={handleGoBack}
             className="arrowback-newdelliverychallan"
             fontSize="larger" /* or "small", "", "medium" */
            />

        {/* Invoice Form */}
        
        <form onSubmit={handleSubmit} className="newdelliverychallan-form">
          <div>
          <div className="newdelliverychallan-header1" >
            <input
            placeholder="Customer Name"
              type="text"
              name="customerName"
              value={invoice.customerName}
              onChange={handleInputChange}
              required
            />
           <ReceiptLongOutlinedIcon  className="receipt-newdelliverychallan"
             fontSize="larger" /* or "small", "", "medium" *//> <b>Pending Invoices</b>
           <hr class="vertical-line1" />
           <c>View Customer Details</c>
           <u>Billing Address</u>
          </div></div>
          <div className="newdelliverychallan-header2">
         
            
            
          <label className="challandate1" >Challan Date</label>
            <input
            className="challandate"
              type="date"
              name="invoiceDate"
              value={invoice.invoiceDate}
              onChange={handleInputChange}
              required
            />
              <label className="ewaybill1">E-Way Bill #</label>
            <input
            className="ewaybill"
              type="text"
              name="transportDetails"
              value={invoice.transportDetails}
              onChange={handleInputChange}
            />
            
            <label className="placeofsupply-delliverychallan1">Place of Supply</label>
            <input
            className="placeofsupply-delliverychallan"
              type="text"
              name="placeofSupply"
              value={invoice.placeofSupply}
              onChange={handleInputChange}
            />
               <label className="notes1">Notes</label>
            <input
            className="notes"
              type="text"
              name="invoiceNumber"
              value={invoice.SalesOrderNo}
              onChange={handleInputChange}
            />
           
             <label className="delliverychallannumber1">Dellivery Challan Number</label>
            <input
            className="delliverychallannumber"
              type="text"
              name="pob"
              value={invoice.pob}
              onChange={handleInputChange}
            />
          
          </div>
          <div className="buttons1">
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"20px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b > Payment</b>
          </Button>
          <Button
            variant="contained"
            onClick={handlePrint}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"110px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Print</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"200px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Share</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleEinvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"290px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>E-Invoice</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleEwaybill}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"380px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>E-Way Bill</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"470px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Returns</b>
          </Button>
          </div>


<div className="totals">
  <p>Sub Total: <span>{parseFloat(invoice.subTotal).toFixed(2)}</span></p><hr/>
  <p>SGST: <span>{parseFloat(invoice.sgst).toFixed(2)}</span></p><hr/>
  <p>CGST: <span>{parseFloat(invoice.cgst).toFixed(2)}</span></p><hr/>
  <p>ROUND OFF: <span>{parseFloat(invoice.cgst).toFixed(2)}</span></p><hr/>
  <p>Total Amount: <span>{parseFloat(invoice.totalAmount).toFixed(2)}</span></p>
  <hr class="vertical-line2" />
</div>
<div className="btn-down">
          <Button
          type="submit"
            variant="contained"
            onClick={handleSave}
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"145px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>Save Invoice </b>
          </Button>
          <Button
         
            variant="contained"
       
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"260px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b> + ADD ITEM </b>
          </Button>
          <Button
        
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"30PX",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SETTINGS </b>
          </Button>
          <Button
        
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"260px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SCAN BARCODE </b>
          </Button>
          <Button
          type="submit"
            variant="contained"
            onClick={handleSave}
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"145px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SAVE & PRINT </b>
          </Button>
          <Button
      
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"30px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>CUSTOMER DISPLAY </b>
          </Button>
          <Button
      onClick={handleCancel}
      variant="contained"
     
      sx={{
        position: "absolute",
        left: "900px",
        backgroundColor: "white",
        bottom:"30px",
        color: "black",
        width:"14%",
        height:"80px",
        borderRadius: "10px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "white",
          color: "black",
          boxShadow: "none",
        },
      }}

    >
      <b>CANCEL </b>
      </Button>
        </div>
        </form>
       
        
    </div>
    </body>
  );
};

export default Newdelliverychallan;
