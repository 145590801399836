export const verifyGST = async (gstNumber) => {
    const API_URL = "https://test.zoop.one/api/v1/in/merchant/gstin/lite";
  
    const headers = {
      "Content-Type": "application/json",
      "app-id": "675b358faf0307002847498d", // Replace with your Zoop app-id
      "api-key": "1H4ZASM-6P4MFYV-P254GY9-ZWFGEAV", // Replace with your Zoop API Key
    };
  
    const payload = {
      mode: "sync",
      data: {
        business_gstin_number: gstNumber,
        consent: "Y",
        consent_text:
          "I hereby declare my consent agreement for fetching my information via ZOOP API",
      },
    };
  
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.response_message || "Verification failed");
      }
  
      return data.result; // Return the GST verification result
    } catch (error) {
      console.error("GST Verification Error:", error.message);
      throw error;
    }
  };
  