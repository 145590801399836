import React,{useState} from "react";
import "../styles/invoice.css";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { IconButton, Button } from "@mui/material";
import styled from 'styled-components';
const Invoice = () => {
  const navigate = useNavigate();
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
const handleGoBack = () => {
    navigate("/sales");
  };

  const handleSort = () => {
    navigate("/sort");
  };

  const handleFilter = () => {
    navigate("/filter");
  };

  const handleNewInvoice = () => {
    navigate('/newinvoice'); // Navigates to the previous page in the history stack
  };
  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };
  return (
    <div className="invoice-page">
    {/* Main Content */}
    <main className="main-content">
        <header className="invoice-header">
          <h1>INVOICE</h1>
          <IconButton onClick={handleGoBack} color="primary" aria-label="go back">
          <ArrowCircleLeftOutlinedIcon
             className="arrowback-invoice"
             fontSize="larger" /* or "small", "", "medium" */
            />
            </IconButton>
          <div className="invoice-search">
          <StyledWrapper>
      <div className="searchBox">
        <input className="searchInput" type="text" name placeholder="Search something" />
        <button className="searchButton" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width={29} height={29} viewBox="0 0 29 29" fill="none">
            <g clipPath="url(#clip0_2_17)">
              <g filter="url(#filter0_d_2_17)">
                <path d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges" />
              </g>
            </g>
            <defs>
              <filter id="filter0_d_2_17" x="-0.418549" y="3.70435" width="29.7139" height="29.7139" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_17" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_17" result="shape" />
              </filter>
              <clipPath id="clip0_2_17">
                <rect width="28.0702" height="28.0702" fill="white" transform="translate(0.403503 0.526367)" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </StyledWrapper>
            <PublicSharpIcon
              className="public-invoice"
              fontSize="larger" /* or "small", "", "medium" */
              
              />
              <NotificationsIcon
                className="notification-invoice"
                fontSize="larger" /* or "small", "", "medium" */  
             
                aria-label="notifications"
              />
               {/* Profile Icon */}
             <img
                src="./profile.png"
              className="profile-invoice"
                alt="profile"
              
                onClick={handleProfileClick}
              />
          {/* Profile Overlay */}
          {showProfileOverlay && (
                <div className="profile-overlay">
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                    <z  onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </z>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
            </div>
              
        </header>

        <div className="button-section" style={{ marginTop: "25px", marginLeft: "60%", textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: "1580px",
              top:"170px",
              width:"250px",
              height:"50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
            onClick={handleSort}
          >
            Sort
          </Button>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: "1300px",
              top:"170px",
              width:"250px",
              height:"50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
            onClick={handleFilter}
          >
           Filter
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "65px",
              top:"170px",
              width:"250px",
              height:"50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
             <b>+</b> New Invoice
          </Button>
        </div>
        
        <div className="invoice-list1" >
          <table className="invoice-table1">
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>GSTIN</th>
                <th>Invoice No</th>
                <th>Date</th>
                <th>Invoice Total</th>
                <th>Received</th>
                <th>Returns</th>
                <th>Due Amt</th>
              </tr>
            </thead>
       </table>
       </div>
         
      </main>
    </div>
    
  );
};

const StyledWrapper = styled.div`
  .searchBox {
    display: flex;
    max-width: 350px;
    max-height:60px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background: #2f3640;
    border-radius: 50px;
    position: relative;
    bottom:77px;
    left:900px;
  }

  .searchButton {
    color: white;
    position: absolute;
    right: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--gradient-2, linear-gradient(90deg, #2AF598 0%, #009EFD 100%));
    border: 0;
    display: inline-block;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  }
  /*hover effect*/
  button:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
    transform: translateY(-3px);
  }
  /*button pressing effect*/
  button:active {
    box-shadow: none;
    transform: translateY(0);
  }

  .searchInput {
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 20px;
    padding: 24px 46px 24px 26px;
  }`;
export default Invoice;
