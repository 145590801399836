import React, { useState } from "react";
import "../styles/newinvoice.css";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { Button} from "@mui/material";
import { getDatabase, ref, set, push } from "firebase/database"; // Import Realtime Database methods
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { savePaymentType } from "../firebase";
const Newinvoice = () => {
  const navigate = useNavigate();
  const db = getDatabase(); // Initialize Realtime Database instance
  const [paymentType, setPaymentType] = useState(""); // For storing the selected payment type
  const [details] = useState({}); // Replace with your actual details object
  const [numRows, setNumRows] = useState(4); // Default number of rows (1)
  const [invoice, setInvoice] = useState({
    customerName: "",
    invoiceDate: "",
    dueDate: "",
    poDate: "",
    deliveryDate: "",
    invoiceNumber: "",
    transportDetails: "",
    placeofSupply: "",
    paymentMethod: "",
    pob: "",
    items: Array(4).fill({
      itemName: "",
      mrp: 0,
      hsnCode: "",
      qty: 0,
      sp: 0,
      gst: 0,
      discount: 0,
      value: 0,
    }),
    subTotal: 0,
    sgst: 0,
    cgst: 0,
    totalAmount: 0,
    roundOff: 0,
  });

  // Function to save the payment type
  const handleSave = async () => {
    try {
      await savePaymentType(paymentType, details);
      alert("Payment type saved successfully!");
    } catch (error) {
      console.error("Error saving payment type:", error);
    }
    navigate("/invoice");
  };

  // Function to go back
  const handleGoBack = () => {
    navigate(-1); // Navigates to the previous page in the history stack
  };

  // Handle input changes for invoice
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Add invoice to the database
  const addInvoiceToDatabase = async () => {
    try {
      const invoiceRef = ref(db, "invoices"); // Reference to 'invoices' collection
      const newInvoiceRef = push(invoiceRef); // Generate a new unique key
      await set(newInvoiceRef, invoice); // Save the invoice data
      alert("Invoice saved successfully!");
    } catch (error) {
      console.error("Error saving invoice:", error);
    }
  };

  // Submit the invoice form
  const handleSubmit = (e) => {
    e.preventDefault();
    addInvoiceToDatabase();
  };

  // Print the invoice
  const handlePrint = () => {
    navigate("/newinvoice");
  };

  // Navigate to eInvoice
  const handleEinvoice = () => {
    navigate("/einvoice");
  };

  // Navigate to eWayBill
  const handleEwaybill = () => {
    navigate("/ewaybill");
  };

  // Handle item changes and row addition when last row is filled
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...invoice.items];
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };

    // Check if the last row is filled with itemName, sp, and qty, and if so, add another row.
    if (
      index === updatedItems.length - 1 &&
      updatedItems[index].itemName &&
      updatedItems[index].sp &&
      updatedItems[index].qty
    ) {
      updatedItems.push({
        itemName: "",
        mrp: 0,
        hsnCode: "",
        qty: 0,
        sp: 0,
        gst: 0,
        discount: 0,
        value: 0,
      });
    }

    setInvoice((prev) => ({
      ...prev,
      items: updatedItems,
    }));

    // Recalculate totals after item change
    calculateTotals(updatedItems);
  };

  // Function to calculate the value of each item row
  const calculateValue = (item) => {
    const { sp, gst, discount, qty } = item;
    const discountedPrice = sp - (sp * discount) / 100;
    const value = discountedPrice * qty * (1 + gst / 100);
    return value.toFixed(2);
  };

  // Function to calculate the Sub Total, SGST, CGST, Round Off, and Total Amount
  const calculateTotals = (updatedItems) => {
    let subTotal = 0;
    
    updatedItems.forEach((item) => {
      subTotal += parseFloat(calculateValue(item)) || 0;
    });

    // Calculate SGST and CGST as 9% of Sub Total
    const sgst = subTotal * 0.09;
    const cgst = subTotal * 0.09;

    // Calculate Round Off
    const totalAmount = subTotal + sgst + cgst;
    const roundOff = Math.round(totalAmount) - totalAmount;

    // Update the invoice state with the new totals
    setInvoice((prev) => ({
      ...prev,
      subTotal,
      sgst,
      cgst,
      roundOff,
      totalAmount: subTotal + sgst + cgst + roundOff,
    }));
  };

  // Function to reset the form and cancel the operation
  const handleCancel = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel? All unsaved changes will be lost."
      )
    ) {
      const form = document.getElementById("salesOrderForm");
      if (form) {
        form.reset();
      }
      window.location.reload();
    }
  };

  // Navigate to new invoice page
  const handleNewInvoice = () => {
    navigate('/ewaybill'); // Navigates to the previous page in the history stack
  };

  return (
    <body>
    <div className="newinvoice-page">
   
    <ArrowCircleLeftOutlinedIcon
    onClick={handleGoBack}
             className="arrowback-newinvoice"
             fontSize="larger" /* or "small", "", "medium" */
            />

        {/* Invoice Form */}
        
        <form onSubmit={handleSubmit} className="invoice-form">
          <div>
          <div className="header1" >
            <input
            placeholder="Customer Name"
              type="text"
              name="customerName"
              value={invoice.customerName}
              onChange={handleInputChange}
              required
            />
           <ReceiptLongOutlinedIcon  className="receipt-newinvoice"
             fontSize="larger" /* or "small", "", "medium" *//> <b>Pending Invoices</b>
           <hr class="vertical-line1" />
           <c>View Customer Details</c>
           <u>Billing Address</u>
          </div></div>
          <div className="header2">
          <div className="radio">
      
      <label className="radio1">
        <input
        className="radioinput"
          type="radio"
          name="paymentType"
          value="cash"
          onChange={(e) => setPaymentType(e.target.value)}
          required
        />
        <c>Cash</c>
      </label>
      <label className="radio2">
        <input
        className="radioinput1"
          type="radio"
          name="paymentType"
          value="credit"
          onChange={(e) => setPaymentType(e.target.value)}
          required
        />
       <c>Credit</c> 
      </label>
  
    </div>
            
         <label className="invoicedate1" >Invoice Date</label>
            <input
            className="date"
              type="date"
              name="invoiceDate"
              value={invoice.invoiceDate}
              onChange={handleInputChange}
              required
            />
            <label className="duedate1">Due Date</label>
            <input
            className="duedate"
              type="date"
              name="dueDate"
              value={invoice.dueDate}
              onChange={handleInputChange}
            />
              <label className="podate1">P.O Date</label>
            <input
            className="podate"
              type="date"
              name="poDate"
              value={invoice.poDate}
              onChange={handleInputChange}
            />
              <label className="deliverydate1">Delivery Date</label>
            <input
            className="deliverydate"
              type="date"
              name="deliveryDate"
              value={invoice.deliveryDate}
              onChange={handleInputChange}
            />
              <label className="transportdetails1">Transport Details</label>
            <input
            className="transportdetails"
              type="text"
              name="transportDetails"
              value={invoice.transportDetails}
              onChange={handleInputChange}
            />
            
            <label className="placeofsupply1">Place of Supply</label>
            <input
            className="placeofsupply"
              type="text"
              name="placeofSupply"
              value={invoice.placeofSupply}
              onChange={handleInputChange}
            />
               <label className="invoicenumber1">Invoice Number</label>
            <input
            className="invoicenumber"
              type="text"
              name="invoiceNumber"
              value={invoice.invoiceNumber}
              onChange={handleInputChange}
            />
           
             <label className="pob1">P.O #</label>
            <input
            className="pob"
              type="text"
              name="pob"
              value={invoice.pob}
              onChange={handleInputChange}
            />
          
          </div>
          <div className="buttons1">
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"20px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b > Payment</b>
          </Button>
          <Button
            variant="contained"
            onClick={handlePrint}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"110px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Print</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"200px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Share</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleEinvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"290px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>E-Invoice</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleEwaybill}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"380px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>E-Way Bill</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"470px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Returns</b>
          </Button>
          </div>

{/* Item Table */}
<div className="invoice-table">
  <div className="table-controls">
  
    
  </div>
  <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Item Name</th>
            <th>M.R.P</th>
            <th>HSN Code</th>
            <th>Qty</th>
            <th>S.P</th>
            <th>GST %</th>
            <th>DIS %</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {invoice.items.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="text"
                  name="itemName"
                  value={item.itemName}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="mrp"
                  value={item.mrp}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="hsnCode"
                  value={item.hsnCode}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="qty"
                  value={item.qty}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="sp"
                  value={item.sp}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="gst"
                  value={item.gst}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="discount"
                  value={item.discount}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </td>
              <td>{calculateValue(item)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {/* Totals calculation */}
      <div className="totals">
        <p>Sub Total: <span>{parseFloat(invoice.subTotal).toFixed(2)}</span></p><hr />
        <p>SGST: <span>{parseFloat(invoice.sgst).toFixed(2)}</span></p><hr />
        <p>CGST: <span>{parseFloat(invoice.cgst).toFixed(2)}</span></p><hr />
        <p>ROUND OFF: <span>{parseFloat(invoice.roundOff).toFixed(2)}</span></p><hr />
        <p>Total Amount: <span>{parseFloat(invoice.totalAmount).toFixed(2)}</span></p>
        <hr className="vertical-line2" />
      </div>
    
<div className="btn-down">
          <Button
          type="submit"
            variant="contained"
            onClick={handleSave}
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"145px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>Save Invoice </b>
          </Button>
          <Button
         
            variant="contained"
       
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"260px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b> + ADD ITEM </b>
          </Button>
          <Button
        
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"30PX",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SETTINGS </b>
          </Button>
          <Button
        
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"260px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SCAN BARCODE </b>
          </Button>
          <Button
          type="submit"
            variant="contained"
            onClick={handleSave}
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"145px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SAVE & PRINT </b>
          </Button>
          <Button
      
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"30px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>CUSTOMER DISPLAY </b>
          </Button>
          <Button
      
      variant="contained"
     onClick={handleCancel}
      sx={{
        position: "absolute",
        left: "900px",
        backgroundColor: "white",
        bottom:"30px",
        color: "black",
        width:"14%",
        height:"80px",
        borderRadius: "10px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "white",
          color: "black",
          boxShadow: "none",
        },
      }}

    >
      <b>CANCEL </b>
      </Button>
        </div>
        </form>
       
        
    </div>
    </body>
  );
};

export default Newinvoice;
