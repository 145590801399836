import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaHome,
  FaChartLine,
  FaBox,
  FaShoppingCart,
  FaDatabase,
  FaCalculator,
  FaFileInvoice,
  FaCog,
  FaSignOutAlt,
} from "react-icons/fa";
import { auth } from "../firebase";
const Sidebar = () => {
  const navigate = useNavigate();
  // Logout Function
  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out from Firebase
      navigate("/login"); // Redirect to the login page after logout
    } catch (error) {
      console.error("Error logging out:", error.message); // Log any errors~
    }
  };

  return (
    <aside className="sidebar">
      <div className="logo">
        <img src="./logo.png" alt="EZBILLIFY" width={"200px"} height={"40px"} />
      </div>
      <nav>
        <ul>
          <li
            className={window.location.pathname === "/dashboard" ? "active" : ""}
            onClick={() => navigate("/dashboard")} 
          >
            <FaHome className="icon" />DASHBOARD
          </li>
          <li
            className={window.location.pathname === "/sales" ? "active" : ""}
            onClick={() => navigate("/sales")}
          >
            <FaChartLine className="icon" /> SALES
          </li>
          <li
            className={window.location.pathname === "/items" ? "active" : ""}
            onClick={() => navigate("/items")}
          >
            <FaBox className="icon" /> ITEMS
          </li>
          <li
            className={window.location.pathname === "/purchase" ? "active" : ""}
            onClick={() => navigate("/purchase")}
          >
            <FaShoppingCart className="icon" /> PURCHASE
          </li>
          <li
            className={
              window.location.pathname === "/masterdata" ? "active" : ""
            }
            onClick={() => navigate("/masterdata")}
          >
            <FaDatabase className="icon" /> MASTER DATA
          </li>
          <li
            className={
              window.location.pathname === "/accounting" ? "active" : ""
            }
            onClick={() => navigate("/accounting")}
          >
            <FaCalculator className="icon" /> ACCOUNTING
          </li>
          <li
            className={
              window.location.pathname === "/gstreturns" ? "active" : ""
            }
            onClick={() => navigate("/gstreturns")}
          >
            <FaFileInvoice className="icon" /> GST RETURNS
          </li>
          <li
            className={window.location.pathname === "/others" ? "active" : ""}
            onClick={() => navigate("/others")}
          >
            <FaCog className="icon" /> OTHERS
          </li>
        </ul>
      </nav>
      <div className="logout" onClick={handleLogout}>
        <img src="./profile.png" width={"35px"}></img>
        <FaSignOutAlt  className="icon1" /> <span >LOGOUT</span>
      </div>
    </aside>
  );
};

export default Sidebar;
